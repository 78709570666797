<template>
  <v-list-item v-if="itemList" class="primary--text" @click="onDownload">
    <v-list-item-icon>
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        size="16"
      />
      <icon-download v-else outlined />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="$t('download.pdf')" />
    </v-list-item-content>
  </v-list-item>
  <v-btn
    v-else
    :loading="loading"
    color="primary"
    text
    @click="onDownload"
    v-text="$t('download')"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconDownload from "@/components/icons/IconDownload.vue";
import useInvoice from "@/composables/invoice";

@Component({
  components: { IconDownload },
})
export default class InvoicePdfDownloadBtn extends Vue {
  @Prop([String, Number]) readonly uuid!: string;
  @Prop(String) readonly xml!: string;
  @Prop(String) readonly filename!: string;
  @Prop(Boolean) readonly itemList!: string;

  loading = false;

  async onDownload() {
    const { downloadPdf } = useInvoice();

    this.loading = true;
    await downloadPdf(this.uuid, this.filename);
    this.loading = false;
  }
}
</script>
