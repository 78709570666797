<template>
  <div v-frag>
    <!-- PAY INVOICE -->
    <invoice-list-action-item
      v-if="canPay"
      :title="$t('invoice.charge')"
      icon="mdi-credit-card-outline"
      @click="onChargeInvoice"
    />

    <!-- COPY INVOICE -->
    <invoice-list-action-item
      v-if="canCopy"
      :title="$t('invoice.copy')"
      icon="mdi-content-copy"
      @click="onCopyInvoice"
    />
    <!--      :to="{ name: 'invoices.copy', params: { id: item.id } }"-->

    <!-- REFUND INVOICE -->
    <invoice-list-action-item
      v-if="canRefund"
      :title="$t('invoice.refund')"
      icon="mdi-wallet-giftcard"
      @click="onRefundInvoice"
    />

    <!-- PRINT INVOICE -->
    <invoice-pdf-print v-if="canPrint" :item="item" item-list />

    <!-- SEND INVOICE TO EMAIL -->
    <invoice-list-action-item
      v-if="canEmail"
      :title="$t('invoice.send.to.email')"
      icon="mdi-email"
      @click="onOpenEmailForm"
    />

    <!-- CANCEL RYA INVOICE -->
    <invoice-list-action-item
      v-if="canBeCancelled"
      :title="$t('cancel.rya.invoice')"
      icon="mdi-cancel"
      @click="onOpenCancelDialog"
    />

    <!-- SYNC INVOICE REFERENCES -->
    <invoice-list-action-item
      v-if="item.has_global_references"
      :title="$t('sync.invoice.references')"
      @click="onOpenInvoiceSync"
    >
      <template #icon>
        <icon-dollar-sign outlined />
      </template>
    </invoice-list-action-item>

    <invoice-pdf-download-btn
      v-if="canDownload"
      :filename="filename"
      :uuid="sUUID ?? item.id"
      item-list
    />

    <template v-if="userIsAdmin">
      <v-divider />

      <!-- INVOICE SIGNED DATE -->
      <invoice-list-action-item :title="$t('date.sign.at')" :to="obUpdateTo">
        <template #icon>
          <icon-calendar-outline outlined />
        </template>
        <template #subtitle>
          {{ $dayjs(item.sign_at).format("L") }}
          {{ $dayjs(item.sign_at).format("HH:mm:ss") }}
        </template>
      </invoice-list-action-item>

      <!-- OPEN XML DIALOG -->
      <invoice-list-action-item
        v-if="canXml"
        :title="$t('invoice.xml.preview')"
        @click="onOpenXmlPreview"
      >
        <template #icon>
          <icon-code-slash-outline outlined />
        </template>
      </invoice-list-action-item>

      <!-- OPEN XML DIALOG -->
      <invoice-list-action-item
        v-if="maillogId"
        :title="$t('invoice.maillog.xml.preview')"
        @click="onOpenMailLogPreview"
      >
        <template #icon>
          <icon-code-slash-outline outlined />
        </template>
      </invoice-list-action-item>

      <!-- DOWNLOAD XML -->
      <invoice-xml-download-btn
        v-if="canDownload"
        :filename="filename"
        :uuid="sUUID ?? item.id"
        item-list
      />
    </template>

    <!-- REBUILD RECEIVED INVOICE -->
    <invoice-list-action-item
      v-if="canRebuild"
      :title="$t('invoice.rebuild.from.xml')"
      icon="mdi-file-refresh-outline"
      @click="onRebuildReceivedInvoice"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type {
  InvoiceData,
  InvoiceMovementTypeData,
} from "@planetadeleste/vue-mc-gw";
import { endsWith, get, isNil, isNumber, isString, toString } from "lodash";
import InvoiceListActionItem from "@/modules/invoices/components/InvoiceListActionItem.vue";
import { AuthModule } from "@/store/auth";
import IconCalendarOutline from "@/components/icons/IconCalendarOutline.vue";
import IconDollarSign from "@/components/icons/IconDollarSign.vue";
import IconCodeSlashOutline from "@/components/icons/IconCodeSlashOutline.vue";
import InvoiceXmlDownloadBtn from "@/modules/invoices/components/InvoiceXmlDownloadBtn.vue";
import InvoicePdfDownloadBtn from "@/modules/invoices/components/InvoicePdfDownloadBtn.vue";
import { number } from "mathjs";
import InvoicePdfPrint from "@/modules/invoices/components/InvoicePdfPrint.vue";
import useInvoice from "@/composables/invoice";
import { LoadingModule } from "@/store/loading";
import { FlashModule } from "@/store/flash";
import { InvoiceTypeModule } from "@/store/invoicetype";
import { MovementTypeModule } from "@/store/movementtype";
import type { InvoiceFlag } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    InvoicePdfPrint,
    InvoicePdfDownloadBtn,
    InvoiceXmlDownloadBtn,
    IconCodeSlashOutline,
    IconDollarSign,
    IconCalendarOutline,
    InvoiceListActionItem,
  },
})
export default class InvoiceListAction extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
  @Prop({ type: String, default: () => "invoices.update" })
  readonly routeName!: string;

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get invoiceTypeList() {
    return InvoiceTypeModule.items;
  }

  get invoiceMovementTypeList() {
    return MovementTypeModule.items;
  }

  get invoiceType() {
    return this.invoiceTypeList.find(
      (obItem) => obItem.id === this.item.invoice_type_id
    );
  }

  get sUUID(): string | undefined {
    return get(this.item, "uuid");
  }

  get signed(): boolean {
    return get(this.item, "is_signed", false);
  }

  get sTypeCode(): number {
    return this.invoiceType ? number(this.invoiceType.code) : 0;
  }

  get isRya() {
    return this.sTypeCode === 701;
  }

  get maillogId(): string | undefined {
    return get(this.item, "maillog_id");
  }

  get obUpdateTo() {
    return { name: this.routeName, params: { id: this.item.id } };
  }

  get canCopy(): boolean {
    const sCode = this.invoiceType?.code;

    return (
      !this.isReceived &&
      !this.isDebt &&
      !isNil(sCode) &&
      (endsWith(sCode, "1") || sCode === "182")
    );
  }

  get canRefund(): boolean {
    const sCode = this.invoiceType?.code;

    return (
      !this.isReceived &&
      !this.isDebt &&
      !this.isRya &&
      !isNil(sCode) &&
      (endsWith(sCode, "1") || sCode === "182")
    );
  }

  get canRebuild(): boolean {
    return this.isReceived; // && !isNil(this.maillogId);
  }

  get canPay(): boolean {
    return (
      !get(this.item, "is_cash") &&
      (this.sTypeCode === 101 || this.sTypeCode === 111) &&
      get(this.item, "balance_value", 0) > 0 &&
      this.canCopy
    );
  }

  get canXml(): boolean {
    return this.signed;
  }

  get canDownload(): boolean {
    return this.canXml;
  }

  get canPrint(): boolean {
    // (!this.sTypeCode || this.sTypeCode !== InvoiceType.CODE_ERECIBO) &&
    return (
      this.signed &&
      (!!this.item.id || !!this.sUUID) &&
      !!this.sTypeCode &&
      !this.isReceived
    );
  }

  get canEmail(): boolean {
    return this.signed && !!this.sTypeCode && !this.isReceived;
  }

  get canBeCancelled(): boolean {
    return (
      this.signed &&
      // @ts-ignore
      (get(this.item, "flag") as InvoiceFlag) !== "rya_cancelled" &&
      this.isRya
    );
  }

  get filename() {
    return this.signed
      ? `${get(this.item, "cfe_name")}_${this.item.order_serial}${
          this.item.order_number
        }`
      : null;
  }

  get obInvoiceMovementType(): InvoiceMovementTypeData | undefined {
    return this.item.invoice_movement_type_id
      ? (MovementTypeModule.items.find(
          (obItem) => obItem.id === this.item.invoice_movement_type_id
        ) as InvoiceMovementTypeData)
      : undefined;
  }

  get isReceived() {
    return !!get(this.item, "received_at");
    // return this.obInvoiceMovementType
    //   ? [8, 9].includes(number(this.obInvoiceMovementType.code))
    //   : false;
  }

  get isDebt() {
    return this.obInvoiceMovementType
      ? [7, 14].includes(number(this.obInvoiceMovementType.code))
      : false;
  }

  onOpenInvoiceSync() {
    this.$emit("open:invoice-sync", this.item.id);
  }

  onOpenXmlPreview() {
    this.$emit("open:xml-preview", this.sUUID ?? this.item.id);
  }

  onOpenMailLogPreview() {
    this.$emit("open:maillog-preview", this.item);
  }

  onOpenEmailForm() {
    this.$emit("open:email-form", this.item.id);
  }

  onOpenCancelDialog() {
    this.$emit("open:cancel-rya-dialog", this.item);
  }

  async onCopyInvoice() {
    if (!this.canCopy) {
      return;
    }

    const { obj } = useInvoice(this.item);

    if (!obj) {
      return;
    }

    try {
      LoadingModule.loading();
      const sId = await obj.copy();
      LoadingModule.loaded();

      if (!sId || !isNumber(sId)) {
        return;
      }

      await this.$router.push({
        name: "invoices.update",
        params: { id: toString(sId) },
      });
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  }

  async onRefundInvoice() {
    if (!this.canRefund) {
      return;
    }

    const { obj } = useInvoice(this.item);

    if (!obj) {
      return;
    }

    try {
      LoadingModule.loading();
      const sId = await obj.refund();
      LoadingModule.loaded();

      if (!sId || !isNumber(sId)) {
        return;
      }

      await this.$router.push({
        name: "invoices.update",
        params: { id: toString(sId) },
      });
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  }

  async onRebuildReceivedInvoice() {
    if (!this.canRebuild) {
      return;
    }

    const sMessage = this.$t("ask.rebuild.received.invoice");
    const bRes = await this.$confirm(sMessage as string, {
      color: "warning",
    });

    if (!bRes) {
      return;
    }

    const { obj } = useInvoice(this.item);

    if (!obj) {
      return;
    }

    try {
      LoadingModule.loading();
      const sId = await obj.rebuildReceived();
      LoadingModule.loaded();

      if (!sId || !isNumber(sId)) {
        return;
      }

      await this.$router.push({
        name: "invoices.received.view",
        params: { id: toString(sId) },
      });
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  }

  async onChargeInvoice() {
    if (!this.canPay) {
      return;
    }

    const { obj } = useInvoice(this.item);

    if (!obj) {
      return;
    }

    try {
      LoadingModule.loading();
      const sId = await obj.charge();
      LoadingModule.loaded();

      if (!sId || !isNumber(sId)) {
        return;
      }

      await this.$router.push({
        name: "invoices.update",
        params: { id: toString(sId) },
      });
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  }
}
</script>
