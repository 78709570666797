<template>
  <svg-icon
    :data="sData"
    v-bind="$attrs"
    class="pkg-ionicons svg-fill"
    outlined
  />
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconCalendarOutline extends Vue {
  sData: string = require("@icon/ionicons/calendar-outline.svg");
}
</script>
